import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../app/store";
import {
  fetchBoutiques,
  getSubdomainAvailability,
  requestApplyBoutiqueChanges, requestCancelDemo,
  requestDeleteBoutique, requestDemoBoutique, requestDeployBoutique,
  updateBoutique
} from "../../app/boutiqueAPI";
import {openSnackBar} from "../global/globalSlice";
import {IProduct} from "../../app/product";
import {arrayMoveMutable} from "../../app/utils";
import {ILogoInfos, TECHNIQUE_DTF, TECHNIQUE_EBD} from "../logos/logosSlice";
import {IUpdateDemoPayload} from "./DemoBoutique";

export interface IBoutiquesState {
  boutiques: IBoutiqueInfos[];
  loaded: boolean;
  currentBoutique: IBoutiqueInfos | null;
  currentProductConfig: ICollectionProduct | null;
  editMode: boolean;
  hasChanges: boolean;
  hasError: boolean;
  error: boolean;
  saving: boolean;
  subdomainTaken: boolean;
  demoBoutiqueOpen: boolean;
  demoBoutiqueLoading: boolean;
  demoBoutiqueError: string | null;
}

const initialState: IBoutiquesState = {
  boutiques: [],
  loaded: false,
  currentBoutique: null,
  currentProductConfig: null,
  editMode: false,
  hasChanges: false,
  hasError: false,
  error: false,
  saving: false,
  subdomainTaken: false,
  demoBoutiqueOpen: false,
  demoBoutiqueLoading: false,
  demoBoutiqueError: null,
};

export interface IBoutiqueInfos {
  id: number | null;
  draftOf: number | null;
  vendorId: number | null;
  logoId: number | null;
  logoUrl: string;
  name: string;
  subtitle: string;
  subdomain: string;
  acronym: string;
  orgaType: string;
  orgaActivity: string;
  website: string;
  facebook: string;
  instagram: string;
  color1: string;
  color2: string;
  persos_active: boolean;
  persos_font: string;
  color_dark_bg: string;
  color_light_bg: string;
  collections: ICollection[];
  customColors: string[] | undefined;
  logosUnsynced: boolean;
  configApplied: boolean;
  buildingAt: string;
  duration: number
  draftCode: string;
  plan: number;
  planAcceptedOn: string;
  termsException: boolean;
}

export const PLAN_STANDARD = 0;
export const PLAN_MARGINS = 1;

export interface ICollection {
  id: number;
  draftOf: number | null;
  name: string;
  hasSiblings: boolean;
  separate: boolean;
  products: ICollectionProduct[];
  groups: ICollectionGroup[];
  nbProducts?: number;
}

export const CP_CHANGE_NONE = 0
export const CP_CHANGE_BASICS = 1    // name, position, collection
export const CP_CHANGE_PRICES = 2
export const CP_CHANGE_PERSOS = 3
export const CP_CHANGE_IMAGES = 4


export interface ICollectionProduct {
  id: number;
  draftOf: number | null;
  collectionId: number;
  product: IProduct;
  productId: number | null;
  version: number;
  persoAllowed: boolean;
  multiLogos: boolean;
  persos: IPersoInfos[];
  colors: ICollectionProductColor[];
  margin: number;
  customTitle: string | null;
  changesLevel: number;
  imageUpdating?: boolean;
  hasError: boolean;
  errors: string[];
}

export interface IPersoInfos {
  addonId: number;  // this is the addon id (among the addons of first color images of the configured product)
  name: string;
  activated: boolean;
  content: string;  // Text, Number, Initials

  // color of the perso in each color of the configured product, if not blank.
  // If blank, the persoColor of CollectionProductColor is used
  colorList: string[];
}

export interface ICollectionProductColor {
  colorImages: IProductColorImages;
  promote: boolean;
  logoAddons: IAddonInfos[];
  persoColor: string; // default colors of all perso addons of the color, il blank, defined at CollectionProduct level, in persos
  hexColor1: string;
  hexColor2: string;
}

export interface IProductColorImages {
  color: string;
  discontinued?: boolean;
  friendlyColor: string;
  hexColor1: string | undefined;
  hexColor2: string | undefined;
  images: IProductImage[];
}

export interface IProductImage {
  id: number;
  url: string;
  boutiqueUrl: string | null;
  logoAddons: IImageAddon[];
  persoAddons: IImageAddon[];
}

export interface IImageAddon {
  id: number;
  name: string;
  lightBg: boolean;
  maxWidthSmall: number;
  maxWidthLarge: number;
  maxHeightSmall: number;
  maxHeightLarge: number;
  excludes: string[];
}

export interface IAddonInfos {
  addonId: number;
  name: string;
  maxWidthSmall: number;
  maxWidthLarge: number;
  maxHeightSmall: number;
  maxHeightLarge: number;
  logoIds: number[];            // in case of multiple logos to choose from
  logoVersionIds: number[];    // in case of multiple logos to choose from
  zoom: number;
  optional: boolean;
}

interface ICollectionGroup {
  manual: boolean;
  head: string;
  siblings: ICollectionSibling[];
}

interface ICollectionSibling {
  key: string
  id: number; // id of the original product
}

export const DefaultPersoContent = (imageAddon: IImageAddon) => {
  let content = '';
  if (imageAddon.name.startsWith('small')) {
    content = 'text';
  } else if (imageAddon.name.startsWith('big') && imageAddon.name.includes('middle')) {
    content = 'number';
  } else {
    content = 'text';
  }
  return content;
}

export const DefaultLogoVersion = (imageAddon: IImageAddon, logo: ILogoInfos, junior: boolean, category: string) =>
  logo.versions.find((logoVersion) => {

    if (category.startsWith('martial')) {
      return true;
    } else {

      switch (category) {
      case 'headwear':
        return logoVersion.name === 'Couvre-chef';

      case 'bags1-small':
      case 'bags4-medium':
      case 'bags8-large':
        switch (category.split('-')[1]) {
          case 'small':
            return logoVersion.name === 'Standard';

          case 'medium':
            return logoVersion.name === 'Moyen';

          case 'large':
            return logoVersion.name === 'Grand';
        }
        break

      default:
        switch (imageAddon.name.startsWith('big') ? 'big' : 'small') {
          case 'small':
            return logoVersion.name === (junior ? 'Junior' : 'Standard');

          case 'big':
            return logoVersion.name === (junior ? 'Moyen' : 'Grand');
        }
      }
    }
  }
)

// for a given logo addon (from an image)
// returns the default logo for an addon (based on provided list of logos and their light/dark background property)
const defaultLogoForAddon = (imageAddon: IImageAddon, junior: boolean, category: string, logos: ILogoInfos[]) => {

  if (logos.length > 0) {

    let logoForAddon:ILogoInfos;

    // For products with martial arts categories, we use the EBD logo
    if (category.startsWith('martial')) {
      logoForAddon = logos.find((logo) => logo.technique === TECHNIQUE_EBD) || logos[0];
    } else {

      // try to match the background of the addon
      const firstLogoMatchingAddonBg = logos
        .filter((logo) => logo.technique === TECHNIQUE_DTF)
        .find((logo) => imageAddon.lightBg ? (logo.forDarkBg === 0) : (logo.forDarkBg >= 1));

      logoForAddon = firstLogoMatchingAddonBg || logos[0];
    }

    const logoVersion = DefaultLogoVersion(imageAddon, logoForAddon, junior, category);

    if (logoForAddon && logoVersion) {
      return {
        logoId: logoForAddon.id,
        logoVersionId: logoVersion.id,
      }
    }
  }
}

//----------------------------------------------------
//------------------- BOUTIQUE COLORS -------------------
// in the back end, we save colors as hex values
// here on the frontend, we use color1, color2, etc. whenever possible

export const BoutiqueAllColors = (boutique: IBoutiqueInfos) => ([boutique.color1, boutique.color2].concat(boutique.customColors || []));

// from boutiqueColor to hexColor
export const BoutiqueColorToHex = (boutiqueColors: string[], color: string) => {

  if ((color === 'white') || (color === 'black') || (color === '') || (color === undefined)) return color;

  if (color === 'new-color') return '#000000';

  return boutiqueColors[parseInt(color.substring(5)) - 1];
}

// export const BoutiquePersoColorHex = (colorCode: string, boutiqueColors: string[]) => {
//   switch (colorCode) {
//     case 'white':
//       return '#FFFFFF';
//     case 'black':
//       return '#000000';
//     case 'new-color':
//       return '#808080';
//     default:
//       return boutiqueColors[parseInt(colorCode.substring(5)) - 1];
//   }
// }

// from hexColor to boutiqueColor
export const DetectBoutiqueColors = (boutiqueColors: string[], hexColor: string) => {
  if (!hexColor) return '';

  switch(hexColor.toLowerCase()) {
    case '':
      return '';

    case '#ffffff':
    case 'white':
      return 'white';

    case '#000000':
    case 'black':
      return 'black';

    default:
      const colorIdx = boutiqueColors.findIndex((c) => c === hexColor);
      return((colorIdx !== -1) ? `color${colorIdx+1}` : hexColor);
  }
}


export const PersoAddonPrice = (imageAddon: IImageAddon, content: string) => {

  const addonSmallSize = imageAddon.name.startsWith('small');
  const addonForBag = imageAddon.name.includes('bag');

  // console.log("persoAddonPrice for " + imageAddon.name + " with content " + content);

  switch(content) {
    case 'initials':
      return 350;

    case 'text':
      if (addonForBag) {
        return addonSmallSize ? 550 : 750;
      } else {
        return addonSmallSize ? 450 : 550;
      }

    case 'number':
      return addonSmallSize ? 350 : 550;

    default:
      return 0;
  }
}


export const AllPersoAddonsActivatedToDefault = (
  colors: IProductColorImages[],
  defaultColorDarkBg: string,
  defaultColorLightBg: string) => colors[0].images.

    map((image: IProductImage) => image.persoAddons).flat().
    map((persoAddon: IImageAddon) => {
      return ({
        addonId: persoAddon.id,
        name: persoAddon.name,
        activated: true,
        content: DefaultPersoContent(persoAddon),
        // colorList: Array(colors.length).fill(''),
        colorList: colors.map((color: IProductColorImages, index) => {
          if (index === 0) {
            return persoAddon.lightBg ? defaultColorLightBg : defaultColorDarkBg;
          } else {
            const equivalentAddonOnThisColor = color.images
              .map((image: IProductImage) => image.persoAddons).flat()
              .find((addon: IImageAddon) => addon.name === persoAddon.name);

            if (equivalentAddonOnThisColor) {
              return equivalentAddonOnThisColor.lightBg ? defaultColorLightBg : defaultColorDarkBg;
            } else {
              return defaultColorLightBg;
            }
          }
        }),
      } as IPersoInfos);
    });

const addonsBackgrounds = (colors: IProductColorImages[]) => colors.map((color: IProductColorImages) => {

  const imagesBackgrounds = color.images
    .filter((image: IProductImage) => (image.persoAddons.length > 0))
    .map((image: IProductImage) => {
      const imagePersoAddonAllLightBg = image.persoAddons.every((persoAddon: IImageAddon) => persoAddon.lightBg);
      const imagePersoAddonAllDarkBg = image.persoAddons.every((persoAddon: IImageAddon) => !persoAddon.lightBg);

      const imageAddonsBg = imagePersoAddonAllLightBg ? 'light' : (imagePersoAddonAllDarkBg ? 'dark' : 'mixed');
      // console.log("addonsDefaultColors for " + color.color + " with image " + image.id + " => " + imageAddonsBg);
      return(imageAddonsBg);
    });

  const colorPersoAddonAllLightBg = imagesBackgrounds.every((imageBg: string) => imageBg === 'light');
  const colorPersoAddonAllDarkBg = imagesBackgrounds.every((imageBg: string) => imageBg === 'dark');
  const colorPersoBg = colorPersoAddonAllLightBg ? 'light' : (colorPersoAddonAllDarkBg ? 'dark' : 'mixed');

  // console.log("colorPersoBg for " + color.color + " => " + colorPersoBg);
  return(colorPersoBg);
});

export const ProductAllAddons = (colProduct: ICollectionProduct) => colProduct.colors[0].colorImages.images
  .map((image) => (image.logoAddons.concat(image.persoAddons))).flat()

// Given a collection product, and an addon name, return the list of addons that are excluded
export const ColProductAddonExcludedAddons = (colProduct: ICollectionProduct, addonName: string) =>
  ProductAllAddons(colProduct).find(a => a.name === addonName)?.excludes || [];

// Given a collection product, and a list of (selected) addons, return the list of addons that are excluded
export const ColProductExcludedAddonsForLogoAddons = (colProduct: ICollectionProduct, logoAddons: IAddonInfos[][]) =>
  logoAddons.map((addons) => addons.map(a => ColProductAddonExcludedAddons(colProduct, a.name)))
    .flat(2)
    .filter((v,i,a)=>a.indexOf(v)==i); // unique values

const collectionHasSiblings = (collection_products: ICollectionProduct[]) => {

  // console.log("collectionHasSiblings for " + collection_products.length + " products");

  const hasSiblings = collection_products.some((cp1: ICollectionProduct) =>
    cp1.product.connectedProductIds.some((pid: number) =>
      collection_products.some((cp2: ICollectionProduct) => (cp2 !== cp1 && cp2.product.id === pid)
      )))

  // console.log("collectionHasSiblings => " + hasSiblings);

  return hasSiblings;
}

const collProductUniqueKey = (product: ICollectionProduct) => {
  // return(product.productId !== null ? product.productId.toString() : product.product.id + "-" + product.version);
  if (product.product) {
    return (product.product.id + "-" + product.version);
  } else {
    console.log("collProductUniqueKey: product is null for", product);
    return '';
  }
}

const dumpGroups = (collection: ICollection, color:string) => {

  // console.log("%cGroups of collection " + collection.id, "color: " + color);
  collection.groups.forEach((group: ICollectionGroup) => {

    const groupProductPositions = group.siblings.map((sibling: ICollectionSibling) => indexInCollection(collection, sibling.key, sibling.id))

    // console.log("%c  " + group.head + " : " + group.siblings.map((sibling: ICollectionSibling) => sibling.key).join(', ') + " with product positions " + groupProductPositions.join(', '), "color: " + color);
  });
}

const dumpProducts = (color:string, products: ICollectionProduct[]) => {
  // console.log("%cProducts of collection " + products[0]?.collectionId, "color: " + color);
  // products.forEach((cp: ICollectionProduct, cp_index: number) => {
  //   console.log("%cposition " + cp_index + " : (" + collProductUniqueKey(cp) + ") " + cp.product.title + "(" + cp.product.id + ")  connections " + cp.product.connectedProductIds.join(', '), "color: " + color);
  // });
}

const belongsToGroup =(collection: ICollection, key: string, id: number ) => {
  return(collection.groups.find((group: ICollectionGroup) =>
    group.siblings.some((sibling: ICollectionSibling) => sibling.key === key && sibling.id === id)));
}

const indexInProducts = (products: ICollectionProduct[], key: string, id: number) =>
  products.findIndex((cp: ICollectionProduct) => collProductUniqueKey(cp) === key && cp.product.id === id);

const indexInCollection = (collection: ICollection, key: string, id: number) => indexInProducts(collection.products, key, id);

const groupPosition = (products: ICollectionProduct[], group: ICollectionGroup) => indexInProducts(products, group.siblings[0].key, group.siblings[0].id);

const removeProductInGroups = (collection: ICollection, id:number, key: string) => {

  // console.log("%cremoveProductInGroups for " + key + " (" + id + ")", "color: purple");
  collection.groups.filter((g:ICollectionGroup) => !g.manual).forEach((group: ICollectionGroup) => {

    // if we are removing the first product of the group, we need to update the group head
    // with the future first product of the group
    if (group.head === key) {
      group.head = group.siblings[1].key;
    }
    group.siblings = group.siblings.filter((sibling: ICollectionSibling) => !(sibling.key === key && sibling.id === id));

    // if the group is reduced to one product, we remove the group
    if (group.siblings.length === 1) {
      collection.groups = collection.groups.filter((g: ICollectionGroup) => g !== group);
      return;
    }
  });

}

export const productAttachedRight = (collection: ICollection, collProduct: ICollectionProduct) =>
  collection.groups.some((group: ICollectionGroup) => {
    const index = group.siblings.findIndex((sibling: ICollectionSibling) => sibling.key === collProductUniqueKey(collProduct) && sibling.id === collProduct.product.id);
    if (index > -1) {
      return(group.siblings[index + 1] !== undefined);
    }
    return false;
  });

export const productAttachedLeft = (collection: ICollection, collProduct: ICollectionProduct) =>
  collection.groups.some((group: ICollectionGroup) => {
    const index = group.siblings.findIndex((sibling: ICollectionSibling) => sibling.key === collProductUniqueKey(collProduct) && sibling.id === collProduct.product.id);
    if (index > -1) {
      return(group.siblings[index - 1] !== undefined);
    }
    return false;
  });

const groupProductsAdjacents = (collection: ICollection) => collection.groups.every((group: ICollectionGroup) => {
  const groupProductPositions = group.siblings.map((sibling: ICollectionSibling) => indexInCollection(collection, sibling.key, sibling.id))
  // console.log("Group " + group.head + " has product positions " + groupProductPositions.join(', '));
  return(groupProductPositions.every((siblingIndex: number, siblingIndex_index: number, siblingIndexes: number[]) => {
    return siblingIndex_index === 0 || siblingIndex === siblingIndexes[siblingIndex_index - 1] + 1;
  }));
});


const rearrangedProductInGroups = (collection: ICollection, cp: ICollectionProduct) => {

  const id = cp.product.id;
  const key = collProductUniqueKey(cp)
  const productIndex = indexInCollection(collection, key, id);

  // console.log("\n%crearrangeProductInGroups for " + key + " (" + id + ") at new position " + productIndex, "color: purple");
  // console.log("Before removing the product from all groups");
  // dumpGroups(collection, "orange");

  // remove the product from all groups
  removeProductInGroups(collection, cp.product.id, collProductUniqueKey(cp));

  // console.log("After removing the product from all groups");
  dumpProducts("purple", collection.products);
  dumpGroups(collection, "purple");

  let connected = false;

  // try to connect to an existing group to the left of the new position
  collection.groups.reverse().every((group: ICollectionGroup, group_index: number) => {

    const groupFirstSiblingIndex = indexInCollection(collection, group.siblings[0].key, group.siblings[0].id);
    // console.log("Group " + group.head + " is at position " + groupFirstSiblingIndex);

    // check that this group is to the left of the new position
    if (groupFirstSiblingIndex > productIndex) {

      // console.log("Group " + group.head + " is to the right (" + groupFirstSiblingIndex + ") of the new position (" + productIndex + "), skip it")
      return true;  // continue the search for suitable group

    } else {
      // console.log("Group " + group.head + " is to the left (" + groupFirstSiblingIndex + ") of the new position (" + productIndex + "), check if it can be connected")

      if (groupFirstSiblingIndex >= 0) {
        // check if the product is connectable to the first sibling of this group
        const firstSibling = collection.products[groupFirstSiblingIndex];
        // console.log("First sibling of the group is " + firstSibling.product.title + " with connected products " + firstSibling.product.connectedProductIds.join(', '));

        if (firstSibling.product.connectedProductIds.includes(id) &&
          !group.siblings.some((sibling: ICollectionSibling) => sibling.id === id)) {
          // add the product to the group at the right position
          group.siblings.push({key: key, id: id});
          arrayMoveMutable(group.siblings, group.siblings.length - 1, productIndex - groupFirstSiblingIndex);
          if (productIndex > (groupFirstSiblingIndex + group.siblings.length - 1)) {
            arrayMoveMutable(collection.products, productIndex, groupFirstSiblingIndex + group.siblings.length - 1);
          }
          connected = true;
          return false; // stop the search for suitable group
        }
      }
      // console.log("Cannot put in the group " + group.head)
      return true;  // continue the search for suitable group
    }
  });

  // reset in the right order
  collection.groups.reverse();

  if (!connected) {

    // console.log('Not connected to the left, trying to connect to the right')

    // try to connect to an existing group to the right of the new position
    collection.groups.every((group: ICollectionGroup, group_index: number) => {

      const groupFirstSiblingIndex = indexInCollection(collection, group.siblings[0].key, group.siblings[0].id);
      // console.log("Group " + group.head + " is at position " + groupFirstSiblingIndex);

      // check that this group is to the right of the new position
      if (groupFirstSiblingIndex <= productIndex) {

        // console.log("Group " + group.head + " is to the left (" + groupFirstSiblingIndex + ") of the new position (" + productIndex + "), skip it")
        return true;  // continue the search for suitable group

      } else {
        // check if the product is connectable to the first sibling of this group

        if (groupFirstSiblingIndex >= 0) {
          // console.log("Group " + group.head + " is to the right (" + groupFirstSiblingIndex + ") of the new position (" + productIndex + "), check it")
          const firstSibling = collection.products[groupFirstSiblingIndex];
          // console.log("First sibling of the group is " + firstSibling.product.title + " with connected products " + firstSibling.product.connectedProductIds.join(', '));

          if (firstSibling.product.connectedProductIds.includes(id) &&
            !group.siblings.some((sibling: ICollectionSibling) => sibling.id === id)) {

            // console.log("Product " + key + " (" + id + ") is connectable to the group " + group.head + " at position " + groupFirstSiblingIndex);

            // add the product to the group at the first position
            group.siblings.unshift({key: key, id: id});
            group.head = key;
            group.manual = false;

            // move all siblings next to the new head of group
            group.siblings.forEach((sibling: ICollectionSibling, sibling_index: number) => {
              if (sibling_index > 0) {
                const siblingGlobalIndex = indexInCollection(collection, sibling.key, sibling.id);

                // console.log("Before moving right sibling " + sibling_index + " from position " + siblingGlobalIndex + " to position " + (productIndex + sibling_index));

                // dumpProducts("darkred", collection.products);
                arrayMoveMutable(collection.products, siblingGlobalIndex, productIndex + sibling_index);

                // console.log("After moving");
                dumpProducts("darkred", collection.products);
              }
            });
            // console.log("Setting connected to true and exiting the loop");
            connected = true;
            return false; // stop the search for suitable group
          }
        }
        // console.log("Cannot put in the group " + group.head)
        return true;  // continue the search for suitable group
      }
    });
  } else {
    // console.log("Connected to the left, exiting soon")
  }

  if (connected) {
    // console.log("After connecting the product to a group, sort the groups");

    collection.groups.sort((g1, g2) =>
      (groupPosition(collection.products, g1) > (groupPosition(collection.products, g2)) ? 1 : -1))

  }

  dumpGroups(collection, "darkred")

  return connected;
}

const connectCollectionSiblings = (collection: ICollection) => {

  // console.log("%c\n\nStarting connectCollectionSiblings of collection " + collection.name, "color: navy");
  let coll_products = [...collection.products];

  let need_sorting = false;
  let nb_iterations = 0;    // just to avoid infinite loops

  do {

    // console.log("%c\n\nBefore sorting at iteration " + nb_iterations, "color: navy");
    dumpProducts("navy", coll_products);
    dumpGroups(collection, "navy");
    need_sorting = false;

    coll_products.every((cp: ICollectionProduct, cp_index: number) => {

      // console.log("%c " + cp_index + " : " + cp.product.title + "(" + cp.product.id + ") connections " + cp.product.connectedProductIds.join(', '), "color: red, font-weight: bold");
      if (cp.product.connectedProductIds.length > 0) {

        const alreadyInGroup = belongsToGroup(collection, collProductUniqueKey(cp), cp.product.id);

        // if the product is in a group as a sibling, don't do anything with it
        // only search other siblings if the product is the head of the group
        if (alreadyInGroup && !(alreadyInGroup.head === collProductUniqueKey(cp))) {
          return true;
        }

        const nextCollProducts = coll_products.slice(cp_index + 1);
        const reOrderedProducts = [...nextCollProducts]

        let thisProductGroup: ICollectionGroup = alreadyInGroup || {} as ICollectionGroup;
        let nbSiblingsInGroup = alreadyInGroup ? (alreadyInGroup.siblings.length - 1) : 0;
        let nbSiblingsAdded = 0;

        const connectedProductsToSearch = cp.product.connectedProductIds.filter((sibling_pid: number) => (alreadyInGroup === undefined ||
          !alreadyInGroup.siblings.some((sib ) => (sib.id === sibling_pid))))

        if (connectedProductsToSearch.length > 0) {
          connectedProductsToSearch.forEach((sibling_pid: number, connectedIdx) => {
            // console.log("Looking for sibling " + connectedIdx + " with original id " + sibling_pid + " in " + reOrderedProducts.length + " products to the right")

            const cp_sibling_index = reOrderedProducts.findIndex((cp2: ICollectionProduct) => (cp2.product.id === sibling_pid)
              && !belongsToGroup(collection, collProductUniqueKey(cp2), cp2.product.id));

            if (cp_sibling_index !== -1) {
              const groupedCollProduct = reOrderedProducts[cp_sibling_index];
              const new_key = collProductUniqueKey(groupedCollProduct)

              // console.log("Found sibling " + connectedIdx + " at reOrderedProducts position " + cp_sibling_index + " (" + new_key + ")");

              arrayMoveMutable(reOrderedProducts, cp_sibling_index, nbSiblingsInGroup);

              // console.log("%cAfter moving " + new_key + " to new reOrderedProducts position : " + nbSiblingsInGroup, "color: orange")
              dumpProducts("orange", reOrderedProducts);

              if (thisProductGroup.head === undefined) {
                thisProductGroup = {
                  manual: false,
                  head: collProductUniqueKey(cp),
                  siblings: [{
                    key: collProductUniqueKey(cp),
                    id: cp.product.id
                  },
                    {
                      key: new_key,
                      id: groupedCollProduct.product.id
                    }]
                }
                // console.log("Adding group item to new group " + thisProductGroup.head);
                collection.groups.push(thisProductGroup);
                dumpGroups(collection, "orange")
              } else {
                // console.log("Adding group item to existing group " + thisProductGroup.head);
                thisProductGroup.siblings.push({
                  key: new_key,
                  id: groupedCollProduct.product.id
                });
                dumpGroups(collection, "orange")

              }

              nbSiblingsInGroup++;
              nbSiblingsAdded++;
            }
          });

          if (thisProductGroup.head !== undefined && nbSiblingsAdded > 0) {
            coll_products = [...coll_products.slice(0, cp_index + 1).concat(reOrderedProducts)];

            // console.log("Group created, so exiting the loop after dumping groups & products");
            dumpGroups(collection, "green")
            dumpProducts("green", coll_products);
            need_sorting = true
            return false;
          } else {
            // console.log("No creation or modification of group, going to the next product");
            return true;
          }
        } else {
          // console.log("No connected products to search, going to the next product");
          return true;
        }

      } else {
        return true;
      }

    });

    nb_iterations++;

    if (nb_iterations > 8) {
      // console.log("%cconnectCollectionSiblings iteration " + nb_iterations, 'color: red');
    }

  } while (need_sorting && nb_iterations < 15);


  // console.log("%cAfter connectCollectionSiblings", "color: darkgreen");
  collection.products = coll_products;
  collection.groups.sort((g1, g2) =>
    (groupPosition(coll_products, g1) > (groupPosition(coll_products, g2)) ? 1 : -1))

  // dumpProducts("darkgreen", coll_products)
  // dumpGroups(collection, "darkgreen")
}

// check if the collection product has logoAddons before checking that they are all non optional
// do this on the first color of the product
const collectionProductHasError = (cp: ICollectionProduct) => {

  let hasError = false;
  let errors: string[] = [];

  // console.log("Checking product " + cp.product.title + " for errors");

  // first check if the product has logo addons
  const hasLogoAddons = cp.colors[0].colorImages.images.flatMap((i) => i.logoAddons).length > 0;
  if (hasLogoAddons && cp.colors.some(
    (color: ICollectionProductColor) => color.logoAddons.filter(
      (la: IAddonInfos) => !la.optional && la.logoIds.length > 0 && la.logoVersionIds.length > 0
    ).length === 0
  )) {
    hasError = true;
    errors.push("no-logo");
  }

  // then check if any of the product colors is discontinued


  return { hasError, errors };
}

const updateHasChanges = (state: IBoutiquesState) => {

  if (state.currentBoutique !== null && state.currentBoutique.id !== null) {
    const currentBoutiqueId = state.currentBoutique.id;
    const boutiqueIndex = state.boutiques.findIndex((b) => b.id === currentBoutiqueId);

    // const anyProductHasChanges = state.currentBoutique.collections.some((collection: ICollection) =>
    //   collection.products.some((product: ICollectionProduct) => product.changesLevel > CP_CHANGE_NONE));

    // const hasChanges = anyProductHasChanges ||
    //   JSON.stringify(state.currentBoutique) !== JSON.stringify(state.boutiques[boutiqueIndex]);
    // const hasChanges = JSON.stringify(state.currentBoutique) !== JSON.stringify(state.boutiques[boutiqueIndex]);

    // exclude the nbProducts property from the comparison because it's only calculated on the currentBoutique
    const hasChanges = JSON.stringify({
      ...state.currentBoutique,
      collections: state.currentBoutique.collections
        .map(({ nbProducts, ...otherProps }) => otherProps)
    }) !== JSON.stringify({
      ...state.boutiques[boutiqueIndex],
      collections: state.boutiques[boutiqueIndex].collections
        .map(({ nbProducts, ...otherProps }) => otherProps)
    });

    // console.log("updateHasChanges for boutique " + currentBoutiqueId + " => " + hasChanges)
    state.hasChanges = hasChanges;

    // first update hasError for each product
    // console.log("%cUpdate hasError for each product in all collections...", "color: blue");
    state.currentBoutique.collections = state.currentBoutique.collections
      .map((collection: ICollection) => (
        {...collection,
          products: collection.products.map((product: ICollectionProduct) => {
            const { hasError, errors } = collectionProductHasError(product);
            return {
              ...product,
              hasError,
              errors,
            };
          }),
        }));
    // console.log("%cDone updating hasError for each product in all collections...", "color: blue");

    // then check if any product has an error
    state.hasError = state.currentBoutique.collections.some((collection: ICollection) =>
      collection.products.some((product: ICollectionProduct) => product.hasError));

    // current boutique needs to have a logoId address
    state.hasError = state.hasError || !state.currentBoutique.logoId;
  }
}

const updateCollections = (state: IBoutiquesState) => {

  // console.log("updateCollections");
  if (state.currentBoutique !== null && state.currentBoutique.id !== null) {

    // console.log("Updating collections for current boutique " + state.currentBoutique.id);

    // update nbProducts property of each collection
    state.currentBoutique.collections = state.currentBoutique.collections.map((collection: ICollection) => {

      // calculate the number of products in the collection
      collection.nbProducts = (collection.hasSiblings && !collection.separate) ?
        collection.products.filter((cp) => !productAttachedLeft(collection, cp)).length :
        collection.products.length;

      return collection;
    });
  }
}

export const getBoutiques = createAsyncThunk(
  "boutique/getBoutiques",
  async (_, thunkAPI) => {

    const response = await fetchBoutiques();
    if (response.error) {
      thunkAPI.dispatch(openSnackBar({severity: 'error', message: "Could not load boutique"}));

      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

const boutiquePayload = (currentBoutique: IBoutiqueInfos) => {
  const allColors = BoutiqueAllColors(currentBoutique);

  // remove not modifiable data from the products
  return {...currentBoutique,
    collections: currentBoutique.collections.map((collection: ICollection) => (
      {...collection,
        products: collection.products.map((cp: ICollectionProduct, index) => (
          {...cp,
            // margin: cp.margin,
            // customTitle: cp.customTitle,
            position: index,
            product: {...cp.product,
              description: "",
              brand: "",
              fabric: "",
              imgScaleDown: 0,
              gender: -1,
              connection: '',
              connectedProducts: [],
              variants: [],
              colorMatch: 0
            },
            colors: cp.colors.map((color: ICollectionProductColor) => (
              {...color,
                colorImages: {...color.colorImages, images: []}
              }
            ))
          }
        ))
      })),
    // not saving customColors because the backend will recalculate the custom colors (named color for the whole boutique)
    // based on the products persos colors and the boutique default perso colors
    customColors: undefined,
    // convert the boutique colors to actual hex values instead of colorX
    color_dark_bg: BoutiqueColorToHex(allColors, currentBoutique.color_dark_bg),
    color_light_bg: BoutiqueColorToHex(allColors, currentBoutique.color_light_bg),
    refresh_all: false
  };
}

export const saveBoutique = createAsyncThunk(
  "boutique/saveBoutique",
    async (refreshAll: boolean, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    if (state.boutique.currentBoutique) {

      const savedBoutiquePayload = boutiquePayload(state.boutique.currentBoutique);
      //  add the refresh_all flag to the payload
      savedBoutiquePayload.refresh_all = refreshAll;

      const response = await updateBoutique(savedBoutiquePayload);

      if (response.error) {
        return thunkAPI.rejectWithValue(response);
      }

      // The value we return becomes the `fulfilled` action payload
      return response;
    }
  }
);


export const applyBoutiqueChanges = createAsyncThunk(
  "boutique/applyBoutiqueChanges",
  async (boutiqueId: number, thunkAPI) => {
    const response = await requestApplyBoutiqueChanges(boutiqueId);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const deleteBoutique = createAsyncThunk(
  "boutique/deleteBoutique",
  async (boutiqueId: number, thunkAPI) => {
    const response = await requestDeleteBoutique(boutiqueId);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const deployBoutique = createAsyncThunk(
  "boutique/deployBoutique",
  async (boutiqueId: number, thunkAPI) => {

    const state = thunkAPI.getState() as RootState;
    if (state.boutique.currentBoutique) {

      const response = await requestDeployBoutique(boutiquePayload(state.boutique.currentBoutique));

      if (response.error) {
        return thunkAPI.rejectWithValue(response);
      }

      // The value we return becomes the `fulfilled` action payload
      return response;

    }
  }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Request demo boutique (for already signed in users)
////////////////////////////////////////////////////////////////////////////////////////////////////

export const askDemoBoutique = createAsyncThunk(
  "boutique/askDemoBoutique",
  async (boutique: IUpdateDemoPayload, thunkAPI) => {
    const response = await requestDemoBoutique(boutique);

    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
    }
);

////////////////////////////////////////////////////////////////////////////////////////////////////
// Cancel demo boutique
////////////////////////////////////////////////////////////////////////////////////////////////////

export const cancelDemoBoutique = createAsyncThunk(
  "boutique/cancelDemoBoutique",
  async (_, thunkAPI) => {
    const response = await requestCancelDemo();

    if (response.error) {
      // The value we return becomes the `rejected` action payload
      return thunkAPI.rejectWithValue(response);
    }

    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);


const boutiqueWithSiblingInfo = (boutique: IBoutiqueInfos) => {
  return {...boutique,
    color_dark_bg: DetectBoutiqueColors(BoutiqueAllColors(boutique), boutique.color_dark_bg),
    color_light_bg: DetectBoutiqueColors(BoutiqueAllColors(boutique), boutique.color_light_bg),
    collections: boutique.collections.map((collection: ICollection) =>
      ({...collection,
        hasSiblings: collectionHasSiblings(collection.products)}))};
}

const addNewBoutique = (state: IBoutiquesState, boutique: IBoutiqueInfos) => {
  const newBoutique = boutiqueWithSiblingInfo(boutique);
  state.boutiques.push(newBoutique);
  state.currentBoutique = newBoutique;
  updateCollections(state);
  state.hasChanges = false;
}

// Can be called following a boutique broadcast, following a boutique save, or following a boutique delete
// event on another session elsewhere
const storeAllBoutiques = (state: IBoutiquesState, boutiques: IBoutiqueInfos[]) => {
  state.boutiques = boutiques.map((boutique: IBoutiqueInfos) => boutiqueWithSiblingInfo(boutique));

  if (boutiques.length > 0) {
    if (state.currentBoutique === null) {
      state.currentBoutique = boutiqueWithSiblingInfo(boutiques[0])   // siblingInfo is not yet stored in the state
    } else {
      // let's try to update the current boutique with the new data,
      // otherwise (in case of current boutique deletion from another session or user)
      // fallback to the first boutique
      const currentBoutiqueId = state.currentBoutique.id;
      // the current boutique still in the list of boutiques ?
      const boutiqueIndex = state.boutiques.findIndex((b) => b.id === currentBoutiqueId);
      state.currentBoutique = boutiqueWithSiblingInfo(boutiques[boutiqueIndex === -1 ? 0 : boutiqueIndex]);
    }

    updateCollections(state);

  } else {
    state.currentBoutique = null;
  }
  state.error = false;
  state.loaded = true;

  // dumpProducts('green', boutiques[0].collections[0].products);
  // dumpGroups(boutiques[0].collections[0], 'green');
  // dumpProducts('green', boutiques[0].collections[1].products);
  // dumpGroups(boutiques[0].collections[1], 'green');

}

const storeBoutique = (state: IBoutiquesState, boutique: IBoutiqueInfos) => {

  // console.log("%cAfter saving boutique", "color:blue", boutique)
  if (boutique && boutique.id !== null) {

    // either the draft already has its own ID or
    // the draft boutique ID is the negative of the original boutique ID
    const boutiqueIndex = state.boutiques.findIndex((b) =>
      b.id === boutique.id || (boutique.draftOf !== null && b.id === -boutique.draftOf));

    const boutiqueWithSiblingInfo = {...boutique,
      color_dark_bg: DetectBoutiqueColors(BoutiqueAllColors(boutique), boutique.color_dark_bg),
      color_light_bg: DetectBoutiqueColors(BoutiqueAllColors(boutique), boutique.color_light_bg),
      collections: boutique.collections.map((collection: ICollection) =>
        ({...collection, hasSiblings: collectionHasSiblings(collection.products)}))};

    // console.log("%cboutiqueWithSiblingInfo", 'color:blue;', boutiqueWithSiblingInfo);

    state.boutiques[boutiqueIndex] = boutiqueWithSiblingInfo;
    state.currentBoutique = boutiqueWithSiblingInfo;
    updateCollections(state);
  }
  state.hasChanges = false;
}

const updateProductColors = (state: IBoutiquesState, previousColor: string, newColor: string) => {
  // console.log("previous color", previousColor);
  state.currentBoutique?.collections.forEach((collection) => {
    collection.products.forEach((product) => {
      product.colors.forEach((color) => {
        if (color.persoColor === previousColor) {
          // console.log(product.product.title, " color ", color.colorImages.color, " Replacing color ", color.persoColor, " with ", action.payload.value);
          color.persoColor = newColor;
        }
      });
      product.persos.forEach((perso) => {
        perso.colorList = perso.colorList.map((color) => {
          if (color === previousColor) {
            // console.log(product.product.title, " perso ", perso.name, " Replacing color ", color, " with ", action.payload.value);
            return(newColor);
          } else return color;
        });
      });
    })
  });
}

const updatePersoProductColors = (state: IBoutiquesState, darkOrLight: string, newColor: string) => {
  // console.log("updatePersoProductColors for " + darkOrLight + " with " + newColor);
  if (state.currentBoutique) {
    const allColors = BoutiqueAllColors(state.currentBoutique);
    const newColorHex = BoutiqueColorToHex(allColors, newColor);
    // console.log("updatePersoProductColors with allColors", allColors, "newColor", newColor, "newColorHex", newColorHex);

    state.currentBoutique?.collections.forEach((collection: ICollection) => {
      collection.products.forEach((product: ICollectionProduct) => {

        const persoBackgrounds = addonsBackgrounds(product.colors
          .map((color: ICollectionProductColor) => color.colorImages));

        product.colors.forEach((color: ICollectionProductColor, colorIndex: number) => {
          // console.log(product.product.title + " color " + color.colorImages.color + " has perso color " + color.persoColor + " new is ", newColor);
          let newPersoColorHex = color.persoColor;
          const persoBackground = persoBackgrounds[colorIndex];

          if ((persoBackground === 'dark' && darkOrLight === 'color_dark_bg') ||
              (persoBackground === 'light' && darkOrLight === 'color_light_bg')) {
            newPersoColorHex = newColorHex;
            // console.log("%cChanging " + product.product.title + " perso color to " + newColor, "color:blue");
          }

          color.persoColor = newPersoColorHex
        });

        product.persos.forEach((perso: IPersoInfos) => {
          perso.colorList = perso.colorList.map((color) => {
            // console.log(product.product.title, " perso ", perso.name, " has color ", color, " new is ", newColor);
            return color;
            // if (color === previousColor) {
            //   // console.log(product.product.title, " perso ", perso.name, " Replacing color ", color, " with ", action.payload.value);
            //   return(newColor);
            // } else return color;
          });
        });
      })
    });
  }
}

const clearProductsMarkup = (state: IBoutiquesState) => {
  // console.log("previous color", previousColor);
  state.currentBoutique?.collections.forEach((collection) => {
    collection.products.forEach((product) => {
      product.changesLevel = product.margin !== 0 ? CP_CHANGE_PRICES : CP_CHANGE_NONE;
      product.margin = 0;
    })
  });
}

export interface RequestPreviewColor {
  colorName: string;
  logoAddons: IAddonInfos[];
}

export interface RequestPreviewProduct {
  id: number;
  productId: number | null;
  version: number;
  colors: RequestPreviewColor[];
}

export interface RequestPreviewData {
  boutiqueId: number,
  product: RequestPreviewProduct
}

export const checkSubdomainAvailability = createAsyncThunk(
  "boutique/checkSubdomainAvailability",
    async (payload: { id: number|null, subdomain: string }, thunkAPI) => {
    const response = await getSubdomainAvailability(payload);
    if (response.error) {
      return thunkAPI.rejectWithValue(response);
    }
  // The value we return becomes the `fulfilled` action payload
  return response;
  }
)

export const boutiqueSlice = createSlice({
  name: "boutique",
  initialState,
  reducers: {
    openDemoBoutique: (state, action) => {
      state.demoBoutiqueOpen = action.payload.open
      state.demoBoutiqueLoading = action.payload.loading
      state.demoBoutiqueError = action.payload.error || null
    },
    updateBoutiques: (state, action) => {
      storeAllBoutiques(state, action.payload);
      updateHasChanges(state);
    },
    clearBoutique: (state) => {
      return initialState
    },
    storeBoutiqueColors: (state, action) => {
      if (state.currentBoutique !== null) {
        if (!state.currentBoutique.customColors) {
          state.currentBoutique.customColors = [];
        }
        const updatedCustomColors = action.payload.slice(2);

        // replicate new colors to all products
        state.currentBoutique.customColors.forEach((previousColor, index) =>
          updateProductColors(state, previousColor, updatedCustomColors[index]))

        state.currentBoutique.customColors =  updatedCustomColors;
        updateHasChanges(state);
      }
    },
    storeBoutiqueInfo: (state, action) => {
      // console.log("storeBoutiqueInfo", action.payload);
      if (state.currentBoutique !== null) {
        const propertyName = action.payload.field as keyof IBoutiqueInfos;
        if (propertyName.startsWith('color')) {

          if (propertyName === 'color_dark_bg') {
            if (state.currentBoutique.color_dark_bg !== action.payload.value) {
              updatePersoProductColors(state, propertyName, action.payload.value);
              state.currentBoutique.color_dark_bg = action.payload.value;   // this is a color code
            }

          } else if (propertyName === 'color_light_bg') {
            if (state.currentBoutique.color_light_bg !== action.payload.value) {
              updatePersoProductColors(state, propertyName, action.payload.value);
              state.currentBoutique.color_light_bg = action.payload.value;   // this is a color code
            }

          } else {
            const colorNumber = parseInt(propertyName.substring(5));
            let previousColor = '';

            switch (colorNumber) {
              case 1:
                previousColor = state.currentBoutique.color1;
                state.currentBoutique.color1 = action.payload.value;    // this is a color hex value
                break;
              case 2:
                previousColor = state.currentBoutique.color2;
                state.currentBoutique.color2 = action.payload.value;    // this is a color hex value
                break;
              default:
                if (state.currentBoutique.customColors === undefined) {
                  state.currentBoutique.customColors = [];
                } else {
                  previousColor = state.currentBoutique.customColors[colorNumber - 3]; // this is a color hex value
                }
                state.currentBoutique.customColors[colorNumber - 3] = action.payload.value;
                break;
              }

            // update all persos using this color since it was changed globally
            if (previousColor !== '') {
              updateProductColors(state, previousColor, action.payload.value);
            }
          }

        } else {
          switch (propertyName) {
            case 'persos_active':
              state.currentBoutique.persos_active = action.payload.value === 'true';
              break;
            case 'plan':
              // convert the plan value to a number
              state.currentBoutique.plan = parseInt(action.payload.value);
              if (state.currentBoutique.plan === PLAN_STANDARD) {
                clearProductsMarkup(state);
              }
              break;

            // case 'planAcceptedOn':
            //   state.currentBoutique.planAcceptedOn = action.payload.value ;
            //   break;
            //
            default:
              (state.currentBoutique[propertyName] as any) = action.payload.value;
          }
        }
        updateHasChanges(state);
      }
    },
    storeCollectionInfo: (state, action) => {
      // console.log("storeCollectionInfo", JSON.stringify(action.payload));
      if (state.currentBoutique !== null) {
        const collectionId = action.payload.id as number;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);
        // console.log("collectionIndex", collectionIndex);
        if (collectionIndex !== -1) {
          (state.currentBoutique.collections[collectionIndex][action.payload.info.field as keyof ICollection] as any) = action.payload.info.value;

          // console.log("stored collection info", action.payload.info.field, action.payload.info.value);
          if (action.payload.info.field === 'separate') {
            if (action.payload.info.value as boolean) {
              state.currentBoutique.collections[collectionIndex].groups = [];
            } else {
              connectCollectionSiblings(state.currentBoutique.collections[collectionIndex]);
            }
          }
          updateCollections(state);
          updateHasChanges(state);
        }
      }
    },
    addCollection: (state, action) => {
      if (state.currentBoutique !== null) {
        state.currentBoutique.collections.push({
          id: 0,
          draftOf: null,
          name: action.payload as string,
          hasSiblings: false,
          separate: false,
          products: [],
          groups: [],
          nbProducts: 0
        })
        updateHasChanges(state);
      }
    },
    deleteCollection: (state, action) => {
      if (state.currentBoutique !== null) {
        state.currentBoutique.collections = state.currentBoutique.collections.filter((collection) => collection.id !== action.payload.collectionId);
        updateHasChanges(state);
      }
    },

    editBoutique: (state) => {
      if (state.currentBoutique !== null && state.currentBoutique.id !== null) {
        const currentBoutiqueId = state.currentBoutique.id;
        const currentBoutiqueIndex = state.boutiques.findIndex((b) => b.id === currentBoutiqueId);

        // look for an existing draft boutique for this boutique
        const draftBoutiqueIndex = state.boutiques.findIndex((b) => b.draftOf === currentBoutiqueId);

        //  if already exists, set the current boutique to the draft boutique
        if (draftBoutiqueIndex !== -1) {
          storeBoutique(state, state.boutiques[draftBoutiqueIndex]);
        } else {

          // create a draft boutique
          const draftBoutique = {...state.boutiques[currentBoutiqueIndex],
            collections: state.boutiques[currentBoutiqueIndex].collections.map((collection) => (
              {...collection,
                // replace the collectionId by the negative of the id
                id: -collection.id,
                draftOf: collection.id,
                // replace the collectionId in the products
                products: collection.products.map((cp) => (
                  {...cp,
                    id: -cp.id,
                    draftOf: cp.id,
                    collectionId: -collection.id
                  }
                ))
              }))
          };
          draftBoutique.id = -currentBoutiqueId;
          draftBoutique.draftOf = currentBoutiqueId;

          // add the new draft boutique to the list of boutiques
          state.boutiques.push(draftBoutique);
          storeBoutique(state, draftBoutique);
        }
      }
    },

    addBoutique: (state, action) => {
      const newBoutiqueInfos = action.payload.boutique;
      state.boutiques.push(newBoutiqueInfos);
      state.currentBoutique = newBoutiqueInfos;
      updateCollections(state);
    },

    selectBoutique: (state, action) => {
      const selectedBoutiqueId = action.payload.id;
      const boutiqueIndex = state.boutiques.findIndex((b) => b.id === selectedBoutiqueId);
      if (boutiqueIndex !== -1) {
        storeBoutique(state, state.boutiques[boutiqueIndex]);
      }
    },

    resetBoutique: (state) => {
      let currentBoutiqueIndex = 0;
      if (state.currentBoutique !== null && state.currentBoutique.id !== null) {
        const currentBoutiqueId = state.currentBoutique.id;
        currentBoutiqueIndex = state.boutiques.findIndex((b) => b.id === currentBoutiqueId);
      }
      storeBoutique(state, state.boutiques[currentBoutiqueIndex])
    },

    deleteDraftBoutique: (state, action) => {
      // delete the boutique draft with the payload id
      // and select the boutique it was the draft of (provided in the payload)
      state.boutiques = state.boutiques.filter((b) => b.id !== action.payload.id);
      const boutiqueIndex = state.boutiques.findIndex((b) => b.id === action.payload.draftOf);
      storeBoutique(state, state.boutiques[boutiqueIndex] || state.boutiques[0]);
    },

    duplicateBoutique: (state) => {
      let currentBoutiqueIndex = 0;
      if (state.currentBoutique !== null && state.currentBoutique.id !== null) {
        const currentBoutiqueId = state.currentBoutique.id;
        currentBoutiqueIndex = state.boutiques.findIndex((b) => b.id === currentBoutiqueId);

        const newBoutique = {...state.boutiques[currentBoutiqueIndex]};
        newBoutique.id = null;
        newBoutique.name = newBoutique.name + " (copy)";
        storeBoutique(state, newBoutique)

      }

    },

    addProductToCollection: (state, action) => {

      if (state.currentBoutique !== null) {

        const defaultColorDarkBg = state.currentBoutique.color_dark_bg;
        const defaultColorLightBg = state.currentBoutique.color_light_bg;
        const allColors = BoutiqueAllColors(state.currentBoutique);
        const defaultColorDarkBgHex = BoutiqueColorToHex(allColors, defaultColorDarkBg);
        const defaultColorLightBgHex = BoutiqueColorToHex(allColors, defaultColorLightBg);

        // console.log("boutiqueSlice addProductToCollection with payload " + JSON.stringify(action.payload));

        const logos = action.payload.logos;
        const collectionId = action.payload.collectionId;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);
        if (collectionIndex !== -1) {

          const allProductsToAdd = [{product: action.payload.product, colors: action.payload.colors}];
          action.payload.otherVersions.forEach((ov: {product:{},colors:{}}) => allProductsToAdd.push(
              {product: ov.product, colors: ov.colors}));

          const newCollection = {...state.currentBoutique.collections[collectionIndex]};

          const currentCollectionProducts = state.currentBoutique.collections[collectionIndex].products;

          allProductsToAdd.forEach((p_to_add) => {

            const {product, colors} = p_to_add;
            const juniorProduct = product.gender === 3;


            // adding version number to product in the collection
            const existing_versions = currentCollectionProducts
                .filter(p => p.product.id === product.id)
                .map(p => p.version);

            const new_version = existing_versions.length > 0 ? Math.max(...existing_versions) + 1 : 1;

            const persoBackgrounds = addonsBackgrounds(colors);

            const defaultLogos = colors.map((color: IProductColorImages) =>
              color.images.
              filter((image: IProductImage) => (image.logoAddons.length > 0)).
              flatMap((image: IProductImage) => image.logoAddons)[0]
            ).filter((e:any) => e).map((logoAddon: IImageAddon) => {
              const logo = defaultLogoForAddon(logoAddon, juniorProduct, `${product.category}${product.subCategory}`, logos);
              return {
                addonId: logoAddon.id,
                name: logoAddon.name,
                maxWidthSmall: logoAddon.maxWidthSmall,
                maxWidthLarge: logoAddon.maxWidthLarge,
                maxHeightSmall: logoAddon.maxHeightSmall,
                maxHeightLarge: logoAddon.maxHeightLarge,
                logoIds: [logo?.logoId],
                logoVersionIds: [logo?.logoVersionId],
                zoom: 0
              }
            });

            const collProductColors = colors.map((color: IProductColorImages, colorIndex: number) => {
              const persoColorCode = persoBackgrounds[colorIndex] === 'dark' ?
                defaultColorDarkBg : persoBackgrounds[colorIndex] === 'light' ?
                  defaultColorLightBg : '';
              const persoColorHex = BoutiqueColorToHex(allColors, persoColorCode);

              return ({
                colorImages: color,
                promote: false,
                logoAddons: defaultLogos.length > colorIndex ?  [defaultLogos[colorIndex]] : [],
                persoColor: persoColorHex,
              });
            });

            const newCollectionProduct: ICollectionProduct = {
              id: 0,
              draftOf: null,
              collectionId: collectionId,
              product: product,
              productId: null,
              version: new_version,
              persoAllowed: true,
              multiLogos: false,
              persos: [],     // will be updated right after once the collection product is formed
              colors: collProductColors,
              margin: 0,
              customTitle: null,
              changesLevel: CP_CHANGE_NONE,
              hasError: false,
              errors: []
            }

            // get all excluded addons from the automatic choice of logos addons
            const allExcludedAddons = ColProductExcludedAddonsForLogoAddons(newCollectionProduct,
              collProductColors.map((color: ICollectionProductColor) => color.logoAddons))

            // assigned all perso addons except the excluded ones
            // this is also done before saving a product configuration into the boutique
            // in handleSave() of CollectionProductConfig.tsx
            newCollectionProduct.persos =
              AllPersoAddonsActivatedToDefault(colors, defaultColorDarkBgHex, defaultColorLightBgHex)
                .filter((persoAddon) => !allExcludedAddons
                  .some((excludedAddonName: string) => persoAddon.name === excludedAddonName))

            newCollection.products.push(newCollectionProduct);

          });


          if (newCollection.separate) {
            state.currentBoutique.collections[collectionIndex] = newCollection;
            state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);

          } else {

            connectCollectionSiblings(newCollection)

            // Products not rearranged in groups after adding the product, just commit the new collection with the new product and update hasSiblings
            state.currentBoutique.collections[collectionIndex] = newCollection;
            state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);
          }

          updateCollections(state);

          // console.log('\n\n\nupdate the hasChanges state of the boutique !!!\n\n\n');
          updateHasChanges(state);
        }
      }
    },
    removeProductFromCollection: (state, action) => {

      if (state.currentBoutique !== null) {

        const collectionId = action.payload.collectionId;
        const id = action.payload.id;
        const version = action.payload.version;
        const productId = action.payload.productId;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);
        if (collectionIndex !== -1) {

          const newCollection = {...state.currentBoutique.collections[collectionIndex]};

          newCollection.products = newCollection.products.filter((product) => {
              if (productId !== null) {
                return product.productId !== productId;
              } else {
                return product.product.id !== id || product.version !== version;
              }
            }
          );

          if (!newCollection.separate) {
            const cp_key = `${id}-${version}`;
            removeProductInGroups(newCollection, id, cp_key);
            connectCollectionSiblings(newCollection);
          }

          state.currentBoutique.collections[collectionIndex] = newCollection;
          state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);
          updateCollections(state);
          updateHasChanges(state);
        }
      }
    },
    moveProducts: (state, action) => {
      // console.log("boutiqueSlice moveProducts because of " + JSON.stringify(action.payload.movingCollProduct));

      if (state.currentBoutique !== null) {
        const collectionId = action.payload.collectionId;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);

        if (collectionIndex !== -1) {

          const collection = state.currentBoutique.collections[collectionIndex]

          // the moved product might have its version number changed
          const movedCollProduct = action.payload.movingCollProduct;
          const updatedMovedCollProduct = {...action.payload.movingCollProduct};

          const oldCollectionId = action.payload.oldCollectionId;
          // console.log("boutiqueSlice moveProducts from collection " + oldCollectionId + " to collection " + collectionId);

          const newCollProducts = action.payload.collProducts.map((colP: ICollectionProduct) => {

            let productVersion = colP.version;

            // if a new product is in the collection, fix the version number
            if (oldCollectionId !== collectionId && colP.collectionId !== collectionId) {
              // console.log("Moving the product to a new collection " + collectionId + ", fix the version number");
              const existing_versions = collection.products.
                filter(p => p.product.id === movedCollProduct.product.id).map(p => p.version);

              productVersion = existing_versions.length > 0 ? (Math.max(...existing_versions) + 1) : 1;

              // console.log("will assign new version " + productVersion + " to the product " + movedCollProduct.product.id);
              updatedMovedCollProduct.version = productVersion;
            }

            return ({...colP,
              collectionId: collectionId,
              version: productVersion
            });
          });

          dumpProducts('blue', newCollProducts);
          dumpGroups(collection, 'blue');

          const newCollection = {...collection, products: newCollProducts};

          if (newCollection.separate) {
            state.currentBoutique.collections[collectionIndex] = newCollection;
            state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);

          } else {

            const connectedToGroup = rearrangedProductInGroups(newCollection, updatedMovedCollProduct);

            if (!groupProductsAdjacents(newCollection)) {
              // console.log("Groups are not connected anymore, don't apply the new product");
              return;
            }

            if (!connectedToGroup) {
              // the product is not in a group, try to add it to a group
              connectCollectionSiblings(newCollection)
            } else {
              // console.log("The product is connected to a group, don't try to connect the siblings")
            }
            // dumpProducts('purple', newCollProducts);
            // dumpGroups(collection, 'purple');

            // Products not rearranged in groups after adding the product, just commit the new collection with the new product and update hasSiblings
            state.currentBoutique.collections[collectionIndex] = newCollection;
            state.currentBoutique.collections[collectionIndex].hasSiblings = collectionHasSiblings(newCollection.products);

          }

          // console.log("\nRemove the product from the old collection\n")

          // remove the product from old collection if the product changed collection
          if (oldCollectionId !== collectionId) {
            const oldCollectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === oldCollectionId);
            if (oldCollectionIndex !== -1) {
              const oldCollection = {...state.currentBoutique.collections[oldCollectionIndex]};
              oldCollection.products = oldCollection.products.filter((product) => {
                  return product.product.id !== movedCollProduct.product.id || product.version !== movedCollProduct.version;
                }
              );

              // console.log("Dump des produits de l'ancienne collection")
              dumpProducts('red', oldCollection.products);


              if (!oldCollection.separate) {
                const cp_key = `${movedCollProduct.product.id}-${movedCollProduct.version}`;
                removeProductInGroups(oldCollection, movedCollProduct.product.id, cp_key);
                connectCollectionSiblings(oldCollection);
              }

              // dumpProducts('purple', oldCollection.products);
              // dumpGroups(oldCollection, 'purple');

              state.currentBoutique.collections[oldCollectionIndex] = oldCollection;
              state.currentBoutique.collections[oldCollectionIndex].hasSiblings = collectionHasSiblings(oldCollection.products);
            }
          }

          updateCollections(state);
          // console.log('\n\n\nupdate the hasChanges state of the boutique !!!\n\n\n');
          updateHasChanges(state);
        }
      }
    },
    moveCollections: (state, action) => {
      if (state.currentBoutique !== null) {
        state.currentBoutique.collections = action.payload.collCollections;
        updateHasChanges(state);
      }
    },
    openProductConfig: (state, action) => {
      state.currentProductConfig = action.payload;
    },
    saveProductConfig: (state, action) => {
      // console.log("saveProductConfig " + JSON.stringify(action.payload));

      if (state.currentBoutique !== null) {
        const collectionId = action.payload.collectionId;
        const collectionIndex = state.currentBoutique.collections.findIndex((collection) => collection.id === collectionId);

        if (collectionIndex !== -1) {

          const id = action.payload.id;
          const productId = action.payload.productId;
          const productIndex = state.currentBoutique.collections[collectionIndex].products.findIndex(
            (product) => {
              if (productId !== null) {
                return product.productId === productId;
              } else {
                return product.productId === null && product.product.id === id && product.version === action.payload.version;
              }
            }
          );

          if (productIndex !== -1) {
            const collectionProduct = state.currentBoutique.collections[collectionIndex].products[productIndex];

            collectionProduct.persoAllowed = action.payload.persoAllowed;
            collectionProduct.multiLogos = action.payload.multiLogos;
            collectionProduct.persos = action.payload.persos.filter((perso:IPersoInfos) => perso.activated);
            collectionProduct.colors = action.payload.colors.map((color:ICollectionProductColor, index:number) =>
                ({...color,
                  logoAddons: action.payload.colorLogoAddons[index],
                  persoColor: action.payload.persoColors[index]
                }));
            collectionProduct.margin = action.payload.margin;
            collectionProduct.customTitle = action.payload.customTitle;
            collectionProduct.changesLevel = CP_CHANGE_BASICS;

            // raises an error if any color has no non optional logo addon (minimum 1 fixed logo / color)
            // or if one of the colors is discontinued
            const { hasError, errors } = collectionProductHasError(collectionProduct);
            collectionProduct.hasError = hasError;
            collectionProduct.errors = errors;

            // Since the product config is being saved into the currentBoutique new state,
            // we update the new list of colors for the modified boutique
            state.currentBoutique.customColors = action.payload.boutiqueColors.slice(2);

            updateHasChanges(state);
            state.currentProductConfig = null;
          }
        }
      }
    }
  },

  extraReducers: (builder) => {
    builder

      // LOAD USER BOUTIQUES
      .addCase(getBoutiques.pending, (state) => {
        // console.log("getSelection pending");
        // state.saving = true;
        state.loaded = false;
      })
      .addCase(getBoutiques.fulfilled, (state, action: any) => {
        // console.log("getBoutiques fulfilled with " + action.payload.length + " boutiques");
        state.loaded = true;
        storeAllBoutiques(state, action.payload);
        updateHasChanges(state);
      })
      .addCase(getBoutiques.rejected, (state, action: any) => {
        // console.log("getSelection rejected");
        state.loaded = false;
        state.error = true;
      })

      // SAVE ELEMENTS OF THE DRAFT BOUTIQUE
      .addCase(saveBoutique.fulfilled, (state, action: any) => {
        // console.log("%csaveBoutique fulfilled with ", 'color: green', action.payload);
        state.saving = false
        storeBoutique(state, action.payload);
        updateHasChanges(state);
      })
      .addCase(saveBoutique.pending, (state, action: any) => {
        // console.log("%csaveBoutique pending", 'color: orange');
        state.saving = true
      })
      .addCase(saveBoutique.rejected, (state, action: any) => {
        // console.log("saveBoutique rejected with " + JSON.stringify(action.payload));
        state.saving = false
      })

      // APPLY THE CHANGES SAVED IN THE DRAFT BOUTIQUE TO THE PAGOGE REAL DRAFT BOUTIQUE
      .addCase(applyBoutiqueChanges.fulfilled, (state, action: any) => {
        // console.log("%capplyBoutiqueChanges fulfilled with ", 'color: green', action.payload);

        // Upon fulfillment, the draft real pagode boutique has been updated with immediate changes (like color, name)
        // and is building the products (in case there was some product changes)
        // the completion of product changes (which takes time because Pagode needs to create all variant images)
        // will be notified by the backend through a broadcast at the last line of apply_config method in boutique.rb

        // the only change we do here is to update the building and configApplied property of the boutique,
        // to signal the user that the changes to the products are being applied
        // configApplied will return true if there are no images changes

        const boutiqueId = action.payload.boutiqueId;
        const boutiqueIndex = state.boutiques.findIndex((b) => b.id === boutiqueId);

        if (boutiqueIndex !== -1) {
          state.boutiques[boutiqueIndex].buildingAt = action.payload.buildingAt;
          state.boutiques[boutiqueIndex].duration = action.payload.duration;
          state.boutiques[boutiqueIndex].configApplied = action.payload.configApplied;
          if (state.currentBoutique !== null && state.currentBoutique.id === boutiqueId) {
            state.currentBoutique.buildingAt = action.payload.buildingAt;
            state.currentBoutique.duration = action.payload.duration;
            state.currentBoutique.configApplied = action.payload.configApplied;
          }
          // console.log("%capplyBoutiqueChanges fulfilled with buildingAt " + action.payload.buildingAt + " duration " + action.payload.duration, 'color: red');
        } else {
          // this should not happen, the boutique should be in the list of boutiques
          // console.log("%capplyBoutiqueChanges fulfilled with draft boutique id " + boutiqueId + " not found", 'color: red');
        }
        updateHasChanges(state);
        state.saving = false
      })
      .addCase(applyBoutiqueChanges.pending, (state, action: any) => {
        // console.log("%capplyBoutiqueChanges pending", 'color: orange');
        state.saving = true
      })
      .addCase(applyBoutiqueChanges.rejected, (state, action: any) => {
        // console.log("applyBoutiqueChanges rejected with " + JSON.stringify(action.payload));
        state.saving = false
      })

      // OVERWRITE THE ORIGINAL BOUTIQUE WITH THE DRAFT BOUTIQUE PARAMETERS
      .addCase(deployBoutique.fulfilled, (state, action: any) => {
        state.saving = false

        // first delete from state the draft boutique that was deployed
        // this deployed boutique has a draftOf property that points to the payload id
        state.boutiques = state.boutiques.filter((b) => b.draftOf !== action.payload.id);

        // then update the boutique in the list of boutiques with the returned boutique
        // and set it as the current boutique
        storeBoutique(state, action.payload);
        updateHasChanges(state);
      })
      .addCase(deployBoutique.pending, (state, action: any) => {
        // console.log("%cdeployBoutique pending", 'color: orange');
        state.saving = true
      })
      .addCase(deployBoutique.rejected, (state, action: any) => {
        // console.log("saveBoutique rejected with " + JSON.stringify(action.payload));
        state.saving = false
      })

      .addCase(deleteBoutique.fulfilled, (state, action: any) => {
        // delete the boutique from the state
        // check if the deleted boutique was the draft of another boutique, so we can select the original boutique
        // after the deletion
        // otherwise we select the first boutique in the list
        const boutiqueIndex = state.boutiques.findIndex((b) => b.id === action.payload.boutiqueId);
        const boutiqueToSelectId = boutiqueIndex !== -1 ? state.boutiques[boutiqueIndex].draftOf : state.boutiques[0].id;

        state.boutiques = state.boutiques.filter((b) => b.id !== action.payload.boutiqueId);
        state.currentBoutique = state.boutiques.find((b) => b.id === boutiqueToSelectId) || state.boutiques[0];
        state.saving = false
        updateCollections(state);
        updateHasChanges(state);
      })
      .addCase(deleteBoutique.pending, (state, action: any) => {
        // console.log("%cdeleteBoutique pending", 'color: orange');
        state.saving = true
      })
      .addCase(deleteBoutique.rejected, (state, action: any) => {
        // console.log("saveBoutique rejected with " + JSON.stringify(action.payload));
        state.saving = false
      })

      // ASK FOR A DEMO BOUTIQUE WHILE BEING ALREADY SIGNED IN
      .addCase(askDemoBoutique.fulfilled, (state, action: any) => {
        state.saving = false
        addNewBoutique(state, action.payload);
      })
      .addCase(askDemoBoutique.pending, (state, action: any) => {
        state.saving = true
      })
      .addCase(askDemoBoutique.rejected, (state, action: any) => {
        // console.log("saveBoutique rejected with " + JSON.stringify(action.payload));
        state.saving = false
      })

      // Cancel demo boutique
      .addCase(cancelDemoBoutique.pending, (state) => {
        state.saving = true;
        state.error = false;
      })
      .addCase(cancelDemoBoutique.fulfilled, (state, action: any) => {
        state.saving = false;
        storeAllBoutiques(state, []);
        updateHasChanges(state);
      })
      .addCase(cancelDemoBoutique.rejected, (state, action: any) => {
        state.saving = false;
        state.error = true;
      })

      .addCase(checkSubdomainAvailability.pending, (state) => {
        // console.log("checkSubdomainAvailability pending");
        state.subdomainTaken = false;
      })
      .addCase(checkSubdomainAvailability.fulfilled, (state, action: any) => {
        // console.log("checkSubdomainAvailability fulfilled with " + JSON.stringify(action.payload));
        state.subdomainTaken = action.payload.taken;
      })
      .addCase(checkSubdomainAvailability.rejected, (state, action: any) => {
        // console.log("checkSubdomainAvailability rejected");
        state.subdomainTaken = false;
      })
  }
});

export const {
  openDemoBoutique,
  updateBoutiques,
  clearBoutique,
  storeBoutiqueColors,
  storeBoutiqueInfo,
  storeCollectionInfo,
  addBoutique,
  selectBoutique,
  editBoutique,
  deleteDraftBoutique,
  resetBoutique,
  duplicateBoutique,
  addCollection,
  moveCollections,
  deleteCollection,
  addProductToCollection,
  removeProductFromCollection,
  moveProducts,
  openProductConfig,
  saveProductConfig
} = boutiqueSlice.actions;

// const productUsage = (
//   boutiquesState: IBoutiquesState,
//   productId: number,
//   currentBoutiqueOnly: boolean) =>
//
//   boutiquesState.boutiques
//     .filter((b) => !currentBoutiqueOnly || b.id === boutiquesState.currentBoutique?.id)
//     .flatMap((boutique) =>
//     boutique.collections.flatMap((collection: ICollection) => {
//       if (collection.products.some((cp: ICollectionProduct) => cp.product && cp.product.id === productId)) {
//         // return collection.name;
//
//         return collection.products.filter((cp: ICollectionProduct) => cp.product && cp.product.id === productId)
//           .flatMap((cp: ICollectionProduct) => (
//             {
//               boutique: boutique.name,
//               collection: collection.name,
//               colors: cp.colors.map((color: ICollectionProductColor) => color.colorImages.color)
//             }))
//       } else {
//         return null;
//       }
//     }).filter(n => n) || []);

const productUsage = (boutiques: IBoutiqueInfos[], productId: number) =>
  boutiques.flatMap((boutique) =>
    boutique.collections.flatMap((collection: ICollection) => {
      if (collection.products.some((cp: ICollectionProduct) => cp.product && cp.product.id === productId)) {
        // return collection.name;

        return collection.products.filter((cp: ICollectionProduct) => cp.product && cp.product.id === productId)
          .flatMap((cp: ICollectionProduct) => (
            {
              boutique: boutique.name,
              collection: collection.name,
              colors: cp.colors
                .filter((c) => c.colorImages)
                .map((color: ICollectionProductColor) => color.colorImages.color)
            }))
      } else {
        return null;
      }
    }).filter(n => n) || []
  );

// create a selector for filtering the boutiques based on currentBoutiqueOnly
const filteredBoutiques = createSelector(
  [
    (state: IBoutiquesState, _) => state,
    (_, currentBoutiqueOnly: boolean) => currentBoutiqueOnly
  ],
  (boutiqueState, currentBoutiqueOnly) => boutiqueState.boutiques
    .filter((b:  IBoutiqueInfos) => !currentBoutiqueOnly || b.id === boutiqueState.currentBoutique?.id)
);

export const productUsageSelector = createSelector(
  [
    (state: RootState, params: { productId: number; currentBoutiqueOnly: boolean }) =>
      filteredBoutiques(state.boutique, params.currentBoutiqueOnly), // Pass currentBoutiqueOnly to filteredBoutiques
    (_: RootState, params: { productId: number }) => params.productId // Extract productId from params
  ],
  (filteredBoutiques, productId) =>
    productUsage(filteredBoutiques, productId) // Call productUsage with memoized filtered boutiques
);
export default boutiqueSlice.reducer;










